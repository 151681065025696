import {CloudDownload} from "@mui/icons-material";
import {Button, ButtonProps, CircularProgress, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {defined, ErrorAlert, SpringPage, SpringPageable} from "@variocube/app-ui";
import React, {Fragment, useCallback, useRef} from "react";
import {useAsyncCallback} from "react-async-hook";
import {utils, WorkSheet, writeFile} from "xlsx";
import {useLocalization} from "../i18n";

interface ExportButtonProps<T> {
	variant?: ButtonProps["variant"];
	onFetch: (pageable: SpringPageable) => Promise<SpringPage<T>>;
	columns: Column<T>[];
	name: string;
}

export function ExportButton<T>(props: ExportButtonProps<T>) {
	const {
		variant = "outlined",
		onFetch,
		columns,
		name,
	} = props;

	const {t} = useLocalization();

	const cancel = useRef(false);

	const doExport = useCallback(async () => {
		const data: T[] = [];
		let page: SpringPage<T> | undefined = undefined;
		let number = 0;
		do {
			page = await onFetch({page: number});

			if (cancel.current) {
				return;
			}

			if (page.content) {
				data.push(...page.content);
			}
		}
		while (defined(page.totalPages) && number++ < page.totalPages);

		const sheet = generateSheet(data, columns);

		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, sheet, name);
		writeFile(workbook, name + ".xlsx");
	}, [onFetch, columns, name]);

	const {loading, error, execute} = useAsyncCallback(doExport);

	function handleCancel() {
		cancel.current = true;
	}

	return (
		<Fragment>
			<Button variant={variant} color="secondary" startIcon={<CloudDownload />} onClick={execute}>
				{t("export.title")}
			</Button>
			<Dialog open={loading || Boolean(error)}>
				<DialogContent sx={{textAlign: "center"}}>
					<CircularProgress />
					<Typography variant="body2" color="textSecondary">
						{t("export.pending")}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel}>
						{t("actions.cancel")}
					</Button>
				</DialogActions>
				{error && (
					<DialogContent>
						<ErrorAlert error={error} />
					</DialogContent>
				)}
			</Dialog>
		</Fragment>
	);
}

export interface Column<T> {
	name: string;
	value: (data: T) => any;
	type?: "n" | "d";
	format?: string;
	width?: number;
}

export enum ColumnFormat {
	Currency = "#,##0.00",
}

export function generateSheet<T>(data: T[], columns: Column<T>[]) {
	const headers = columns.map(column => column.name);
	const dataFlat = data.map(element => columns.map(column => column.value(element)));

	const sheet = utils.aoa_to_sheet([headers, ...dataFlat]);

	// column widths
	sheet["!cols"] = columns.map(column => ({wch: column.width || 8}));

	columns.forEach((column, columnIndex) => {
		const {type, format} = column;
		if (type && format) {
			for (let row = 1; row <= data.length; row++) {
				const value = dataFlat[row - 1][columnIndex];
				if (type == "n") {
					if (isNumber(value)) {
						applyFormat(sheet, row, columnIndex, type, format, value);
					}
				}
				else {
					applyFormat(sheet, row, columnIndex, type, format, value);
				}
			}
		}
	});

	return sheet;
}

function isNumber(value: any): value is number {
	return typeof value === "number" && isFinite(value);
}

function applyFormat<T>(
	sheet: WorkSheet,
	rowIndex: number,
	columnIndex: number,
	type: string,
	format: string,
	value: any,
) {
	const addr = utils.encode_cell({r: rowIndex, c: columnIndex});
	sheet[addr] = {v: value, t: type, z: format};
}
