import {useApiClient} from "./client";

export function useAuthApi() {
	const api = useApiClient();

	return {
		getLogin: (email: string, origin: string, next: string) =>
			api.get("/api/v2/auth/login/{email}", {
				path: {email},
				query: {origin, next},
			}),
		processToken: (code: string, origin: string) =>
			api.post("/api/v2/auth/process-token", {
				query: {code, origin},
			}),
	};
}
