import {Box, Button, ButtonGroup, Dialog, DialogActions, Stack} from "@mui/material";
import {
	DataTable,
	DataTableColumnSettings,
	SpringPageable,
	useDataTableColumnStorage,
	useDataTableStorage,
	useFlag,
	useSpringPage,
	useSpringPageable,
	View,
	ViewHeader,
} from "@variocube/app-ui";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink, useSearchParams} from "react-router-dom";
import {InvoiceSummary, InvoiceType, useInvoicesApi} from "../../api";
import {Column, ExportButton} from "../../component/ExportButton";
import {hasRoles, useAuthContext, useUser} from "../../context/auth";
import {useLocalization} from "../../i18n";
import {notBlank} from "../../util";
import {canRouteInvoice} from "../../utils/canRoute";
import {useInvoiceColumns} from "./columns";
import {inSameInbox, InvoiceRouting} from "./details/routing";
import {InvoiceFilterBar, useInvoiceFilter} from "./filter";
import {SubmitInvoiceButton} from "./submit-invoice-button";
import {useInvoiceNavigator} from "./useInvoiceNavigator";

export function useInvoicePage() {
	return useDataTableStorage("InvoiceList", {
		sortField: "id",
		sortDirection: "desc",
	});
}

export function InvoiceList() {
	const {s, t} = useLocalization();
	const {listInvoices} = useInvoicesApi();
	const user = useUser();
	const [filter, setFilter] = useInvoiceFilter();
	const storage = useInvoicePage();
	const pageable = useSpringPageable(storage);

	const {setNavigator} = useInvoiceNavigator();

	const [searchParams] = useSearchParams();

	const viewParam = searchParams.get("view");
	const typeParam = searchParams.get("invoiceType");

	const [selected, setSelected] = useState<InvoiceSummary[]>([]);

	useEffect(() => {
		if (notBlank(viewParam)) {
			switch (viewParam) {
				case "Mine":
					setFilter({submittedOrOwnedBy: user.id});
					break;
				case "Audit":
					setFilter({auditRequestedFrom: user.email});
					break;
				case "AwaitingRouting":
					setFilter({awaitingRouting: true});
					break;
				case "AwaitingAudit":
					setFilter({awaitingAudit: true});
					break;
				case "All":
					setFilter({});
					break;
			}
		}
	}, [viewParam]);

	useEffect(() => {
		if (notBlank(typeParam)) {
			setFilter({
				invoiceType: typeParam as InvoiceType,
			});
		}
	}, [typeParam]);

	const query = useMemo(() => ({...filter, ...pageable}), [filter, pageable]);
	const {result, loading, error, execute} = useAsync(listInvoices, [query]);

	const refresh = () => execute(query);

	useEffect(() => {
		if (result) {
			const {number, content, last} = result;
			setNavigator({page: number ?? 0, ids: content?.map(i => i.id) ?? [], last: last ?? false});
		}
	}, [result]);

	const {rows, page} = useSpringPage(result);

	const available = useInvoiceColumns();
	const {columns, setColumns} = useDataTableColumnStorage("InvoiceListColumns", available);

	return (
		<View maxWidth="xl">
			<ViewHeader
				title={t("invoices.plural")}
				actions={
					<ButtonGroup variant="outlined" disableElevation>
						<Button
							component={RouterLink}
							to={`/invoices/export?total=${result?.totalElements ?? 0}`}
							color="inherit"
						>
							{t("invoices.excelExport.button")}
						</Button>
						{/* TODO: <ExportInvoicesButton/> */}
						<SubmitInvoiceButton />
					</ButtonGroup>
				}
			/>
			<Stack direction="row" spacing={2} alignItems="center">
				<Box flex={1}>
					<InvoiceFilterBar
						value={filter}
						onChange={setFilter}
					/>
				</Box>
				<Box>
					<DataTableColumnSettings
						columns={available}
						selected={columns}
						onChange={setColumns}
						labels={s("columnSettings")}
					/>
				</Box>
			</Stack>
			<DataTable
				columns={columns}
				selected={selected}
				onSelectedChange={setSelected}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				{...storage}
				toolbar={
					<Box px={2} py={1}>
						<BulkRouteButton invoices={selected} onRouted={refresh} />
					</Box>
				}
			/>
		</View>
	);
}

interface BulkRouteButtonProps {
	invoices: InvoiceSummary[];
	onRouted: () => any;
}

function BulkRouteButton({invoices, onRouted}: Readonly<BulkRouteButtonProps>) {
	const {t} = useLocalization();
	const {user} = useAuthContext();

	const [open, setOpen, clearOpen] = useFlag(false);
	const [routed, setRouted, clearRouted] = useFlag(false);

	const visible = hasRoles(user, ["SuperUser", "OebbAdministrator", "OebbUser"]);

	const {loading, result} = useRoutableInvoices(visible, invoices);

	useEffect(() => {
		if (!open && routed) {
			onRouted();
			clearRouted();
		}
	}, [open, routed, onRouted]);

	if (!visible) {
		return null;
	}

	return (
		<Fragment>
			<Button variant="outlined" disabled={loading || result?.length == 0} onClick={setOpen}>
				{t("invoices.details.approvalTitle")}
			</Button>
			<Dialog open={open} onClose={clearOpen} fullWidth maxWidth="xs">
				{result && result.length > 0 && <InvoiceRouting invoices={result} onRouted={setRouted} />}
				<DialogActions>
					<Button onClick={clearOpen} color="inherit">{t("actions.close")}</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

function useRoutableInvoices(visible: boolean, invoices: InvoiceSummary[]) {
	const {getInvoice} = useInvoicesApi();
	const user = useUser();

	return useAsync(async (visible: boolean, invoices: InvoiceSummary[]) => {
		// don't load anything, if button is not visible
		if (!visible) {
			return [];
		}

		// avoid loading the invoices, if they are not in the same inbox anyway
		if (!inSameInbox(invoices)) {
			return [];
		}
		const loadedInvoices = await Promise.all(invoices.map(invoice => getInvoice(invoice.id)));
		// return empty array if some invoices are not routable
		if (!loadedInvoices.every(invoice => canRouteInvoice(user, invoice))) {
			return [];
		}
		return loadedInvoices;
	}, [visible, invoices]);
}

function ExportInvoicesButton() {
	const {t} = useLocalization();
	const {listInvoices} = useInvoicesApi();
	const [filter] = useInvoiceFilter();

	async function fetch(pageable: SpringPageable) {
		return await listInvoices({...pageable, ...filter});
	}

	return <ExportButton onFetch={fetch} columns={EXPORT_COLUMNS} name={t("invoices.plural")} />;
}

const EXPORT_COLUMNS: Column<InvoiceSummary>[] = [
	{name: "Rechnungs-ID", value: i => i.id, width: 20},
	// todo: {name: "Barcode", value: i => i.in}
	{name: "Rechnungstyp", value: i => i.id, width: 20},
	{name: "Ländercode", value: i => i.id, width: 20},
	{name: "Lieferant", value: i => i.id, width: 20},
	{name: "Ansprechpartner ÖBB", value: i => i.id, width: 20},
	{name: "Einreicher Name", value: i => i.id, width: 20},
	{name: "Einreicher E-Mail", value: i => i.id, width: 20},
];
